<template>
  <div>
    <div class="row">
      <div class="col-md-7 col-lg-8 col-xl-9">
        <div class="pr-1">
          <chart
            format="pie"
            title="Tabulações"
            :data="dataTabs"
            :loading="loadingTabs"
          />
        </div>
      </div>
      <div class="col-md-5 col-lg-4 col-xl-3">
        <div class="px-6 resume d-flex flex-column flex-grow">
          <div class="card p-2 flex-grow-1">
            <div class="resume_icon">
              <Attendance />
            </div>
            <p>Atendimentos finalizados</p>
            <p>{{ resumeFinished }}</p>
          </div>
          <div class="card p-2 flex-grow-1">
            <div class="resume_icon">
              <PhoneDash />
            </div>
            <p>Nota média dos atendimentos</p>
            <p>{{ resumeEvaluation }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="pr-5">
      <chart
        format="line"
        ref="chart"
        title="Atendimentos por canal"
        :data="dataChannels"
        :loading="loadingChannels"
      />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import StatusChart from '@/components/rebranding/apexcharts/StatusChart.vue';
import Attendance from '@/components/rebranding/animLottie/Attendance.vue';
import PhoneDash from '@/components/rebranding/animLottie/PhoneDash.vue';

import Chart from '@/components/rebranding/Chart.vue';
import axios from '@/plugins/axios';
import moment from 'moment';

export default {
  name: 'OmniReportsStats',
  components: {
    Attendance,
    PhoneDash,
    Chart,
  },
  props: {
    filters: {
      type: Object,
    },
    startDate: {
      type: String,
    },
    endDate: {
      type: String,
    },
  },
  computed: {
    timeWindow() {
      if (moment(this.endDate).diff(moment(this.startDate), 'days')) {
        return 'day';
      }
      return 'hour';
    },
    channels() {
      return this.$parent?.filters?.channels || [];
    },
    tabs() {
      return this.$parent?.filters?.tabs || [];
    },
    tags() {
      return this.$parent?.filters?.tags || [];
    },
  },
  data() {
    const date = new Date().toISOString().substring(0, 10);

    return {
      timeout: 0,
      loadingTabs: false,
      dataTabs: [],

      loadingChannels: false,
      dataChannels: [],
      resumeEvaluation: 0,
      resumeFinished: 0,
    };
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      this.loadingTabs = true;
      this.loadingChannels = true;
      clearTimeout(this.timeout);

      // Prevent multiple requests
      this.timeout = setTimeout(() => this.fetchAll(), 200);
    },
    fetchAll() {
      this.tabulation();
      this.channel();
      this.finished();
    },
    tabulation() {
      const params = {
        start_date: this.startDate,
        end_date: this.endDate,
        time_window: this.timeWindow,
        filter: {
          channels: this.channels,
          tabs: this.tabs,
          tags: this.tags,
        },
        group_by: 'tabulation',
      };

      axios()
        .post('webchat/statistics/data', {
          start_date: this.startDate,
          end_date: this.endDate,
          time_window: this.timeWindow,
          group_by: 'tabulation',
          filter: this.filters,
        })
        .then((resp) => {
          // parse data
          this.dataTabs = resp.data.map((item) => {
            const groupBy = item?.['group_by'];

            if (!groupBy || groupBy === 'null' || groupBy === 'GENERIC') {
              item.group_by = 'Não tabulado';
            }

            console.log(
              'webchat/statistics/data',
              JSON.parse(JSON.stringify(item)),
            );

            item.values = item.values.map((value) => [value.date, value.count]);

            return item;
          });

          console.log(
            'webchat/statistics/data',
            JSON.parse(JSON.stringify(this.dataTabs)),
          );

          // console.log('chart::2', this.date);

          // this.series_line = Object.values(series);
          this.loadingTabs = false;
        });
    },
    channel() {
      const params = {
        start_date: this.startDate,
        end_date: this.endDate,
        time_window: this.timeWindow,
        filter: {
          channels: this.channels,
          tabs: this.tabs,
          tags: this.tags,
        },
        group_by: 'channel',
      };

      axios()
        .post('webchat/statistics/data', {
          start_date: this.startDate,
          end_date: this.endDate,
          time_window: this.timeWindow,
          group_by: 'channel',
          filter: this.filters,
        })
        .then((resp) => {
          console.log('webchat/statistics/data', resp.data);
          // parse data
          this.dataChannels = resp.data.map((item) => {
            const groupBy = item?.['group_by'];

            if (groupBy === 'rcs') {
              item.group_by = 'RCS';
            }

            if (groupBy.indexOf('-java') !== -1) {
              item.group_by = groupBy.replace(/-java$/, '');
            }

            item.values = item.values.map((value) => [value.date, value.count]);
            return item;
          });

          // console.log('chart::2', this.date);

          // this.series_line = Object.values(series);
          this.loadingChannels = false;
        });
    },
    finished() {
      const params = {
        start_date: this.startDate,
        end_date: this.endDate,
        time_window: this.timeWindow,
        filter: {
          channels: this.channels,
          tabs: this.tabs,
          tags: this.tags,
        },
        group_by: 'channel',
      };

      axios()
        .post('webchat/statistics/finished', {
          start_date: this.startDate,
          end_date: this.endDate,
          time_window: this.timeWindow,
          group_by: 'channel',
          filter: this.filters,
        })
        .then((resp) => {
          // this.data = resp;
          // this.series_line = Object.values(series);
          // console.log('webchat/statistics/finished', resp.data);

          this.resumeEvaluation = (resp.data?.evaluation || 0)
            .toFixed(1)
            .replace(/\.[0]*$/, '');
          this.resumeFinished = (resp.data?.finished || 0)
            .toFixed(1)
            .replace(/\.[0]*$/, '');
        });
    },
    exportData(format) {
      this.$refs.chart.exportData(format);
    },
  },
};
</script>

<style lang="scss" scoped>
.resume {
  text-align: center;
  height: 100%;

  .resume_icon {
    width: 150px;
    margin: 0 auto;
  }

  p {
    font-size: 1.1rem;
    margin: 0.5rem 0 0 0;
  }
}
</style>
